import {TextField} from "@mui/material";
import React from "react";
import {makeStyles} from "@mui/styles";
import {FORM_BIRTHDAY, FORMAT} from "../../constants";
import DayPickerInput from "react-day-picker/DayPickerInput";
import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";
import {DateUtils} from "react-day-picker";
import clsx from "clsx";

interface IBirthday {
    value: string,
    setValue: (birthday: string) => void
}

const useStyles = makeStyles({
    picker: {
        width: "100%",
    },
    overlayWrapper: {
        zIndex: 111,
    },
})

const Birthday: React.FC<IBirthday> = ({value, setValue}) => {
    const classes = useStyles();
    const formatDate = (date: Date, format: string) => {
        return dateFnsFormat(date, format);
    }

    const parseDate = (str: string, format: string) => {
        const parsed = dateFnsParse(str, format, new Date());
        if (DateUtils.isDate(parsed)) {
            return parsed;
        }
        return undefined;
    }

    const dayChangeHandler = (e: Date) => {
        if (e) {
            setValue(formatDate(e, FORMAT))
        }
    }

    const changeHandler = (e: React.FormEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        setValue(value);
    }

    return (
        <DayPickerInput
            onDayChange={dayChangeHandler}
            value={value}
            component={TextField}
            inputProps={{
                id: "birthday",
                name: "birthday",
                value: value,
                fullWidth: true,
                label: FORM_BIRTHDAY,
                onChange: changeHandler
            }}
            formatDate={formatDate}
            format={FORMAT}
            parseDate={parseDate}
            placeholder={`dd.mm.yyyy`}
            classNames={{
                container: classes.picker,
                overlay: clsx("DayPickerInput-Overlay", classes.overlayWrapper),
                overlayWrapper: "DayPickerInput-OverlayWrapper"
            }}
        />
    )
}

export default Birthday