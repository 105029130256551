import React, {ReactNode} from "react";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    root: {
        padding: 25,
    }
})

export const Content: React.FC<ReactNode> = ({children}) => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
           {children}
        </div>
    )
}