import api from "./api";
import {IMeResponse, INewUser, ISignInUser, IUpdateUser, IUser, IUserInfo} from "../model";
import {AxiosResponse} from "axios";

export default class UserService {
    static async signIn(body: ISignInUser): Promise<AxiosResponse<IUser>> {
        return api.post<IUser>(`/auth/signin`, body);
    }

    static async logout(): Promise<AxiosResponse> {
        return api.get(`/auth/logout`);
    }

    static async me(): Promise<AxiosResponse<IMeResponse>> {
        return api.get(`/auth/me`);
    }

    static async getAllUsers(): Promise<AxiosResponse<IUserInfo[]>> {
        return api.get<IUserInfo[]>(`/users`);
    }

    static async createUser(body: INewUser): Promise<AxiosResponse<IUserInfo>> {
        return api.post(`/users`, body);
    }

    static async getUser(id: number): Promise<AxiosResponse<IUserInfo>> {
        return api.get(`/users/${id}`);
    }

    static async updateUser(id: number, body: IUpdateUser): Promise<AxiosResponse<IUserInfo>> {
        return api.put(`/users/${id}`, body);
    }

    static async softDeleteUser(id: number): Promise<AxiosResponse> {
        return api.delete(`/users/${id}/soft`);
    }

    static async hardDeleteUser(id: number): Promise<AxiosResponse> {
        return api.delete(`/users/${id}/hard`);
    }
}
