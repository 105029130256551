import {makeAutoObservable} from "mobx";
import {IUpdateUser, IUserInfo} from "../model";
import UsersController from "../controller";

class Users {
    usersList = [] as IUserInfo[];
    isPending = false;

    constructor() {
        makeAutoObservable(this);
    }

    setPending(value: boolean) {
        this.isPending = value;
    }

    setUsersList (users: IUserInfo[]) {
        this.usersList = users
    }

    setUserData (id: number, data: IUpdateUser) {
        this.usersList = this.usersList.map(user => user.id === id ? {...user, ...data} : user);
    }

    addUser (user: IUserInfo) {
        this.usersList.push(user)
    }

    getUser (id: number) {
        const user = this.usersList.find(user => user.id === id );
        return user ? {
            id: user.id,
            name: user.name,
            email: user.email
        } : {} as never
    }
    async getAllUsers () {
        this.setPending(true);
        try {
            const response = await UsersController.getAllUsers();
            this.setUsersList(response.data.filter(user => user.email !== "cwadmin@gmail.com"));
        } catch (e: any) {
            console.error(e.message);
        } finally {
            this.setPending(false);
        }
    }

    async updateUser (id: number, data: IUpdateUser) {
        try {
            const response = await UsersController.updateUser(id, data);
            if (response.success) {
                this.setUserData(id, data);
            }
            return response;
        } catch (e: any) {
            console.error(e.message);
        } finally {
            this.setPending(false);
        }
    }

    async deleteUser (id: number) {
        try {
            const response = await UsersController.deleteUser(id);
            if (response.success) {
                this.setUsersList(this.usersList.filter(user => user.id !== id));
            }
        } catch (e: any) {
            console.error(e.message);
        } finally {
            this.setPending(false);
        }
    }

}
export default Users;
