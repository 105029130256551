import {Header} from "../wrappers/Header";
import Alert from "@mui/material/Alert";
import {Content} from "../wrappers/Content";
import {ErrorMessage, FormikProvider, useFormik} from "formik";
import {Box, Button, CircularProgress, TextField} from "@mui/material";
import React, {useContext} from "react";
import {makeStyles} from "@mui/styles";
import * as Yup from "yup";
import {ISignInUser} from "../model";
import StoreContext from "../mobx";
import {observer} from "mobx-react-lite";

const useStyles = makeStyles({
    error: {
        position: "absolute",
        bottom: -30,
        zIndex: 10,
        paddingTop: 5,
        paddingBottom: 10,
        color: "red",
    }
})

const initial: ISignInUser = {
    email: "",
    password: "",
}

const Login: React.FC = () => {
    const classes = useStyles();
    const {authStore} = useContext(StoreContext);
    const {isPending, loginError} = authStore;

    const validation = Yup.object({
        email: Yup.string()
            .email('Should be email')
            .required('Required'),
        password: Yup.string()
            .required('Required'),
    })

    const formik = useFormik({
        initialValues: initial,
        validationSchema: validation,
        onSubmit: async (values: ISignInUser) => {
            try {
                await authStore.signIn(values);
            } catch (e: any) {
                console.log("Global error")
            }
        },
        validateOnBlur: true,
        validateOnChange: false
    });
    const {handleChange, handleBlur, handleSubmit, values, setErrors, errors} = formik;
    const fieldHandleChange: Function = (fieldName: string) => (e: React.FormEvent<HTMLFormElement>) => {
        setErrors({...errors, [fieldName]: ""});
        handleChange(e);
    }

    return (
        <>
            <Header>Login</Header>
            {loginError && <Alert severity="error">{loginError}</Alert>}
            <Content>
                <FormikProvider value={formik}>
                    <form onSubmit={handleSubmit}>
                        <Box display="flex" flexDirection="column" position="relative">
                            <TextField
                                id="email"
                                name="email"
                                onChange={fieldHandleChange("email")}
                                onBlur={handleBlur}
                                value={values.email}
                                disabled={isPending}
                                variant="standard" label="Email"/>
                            <div className={classes.error}>
                                <ErrorMessage name="email"/>
                            </div>
                        </Box>
                        <Box display="flex" flexDirection="column" position="relative" mb={4} mt={4}>
                            <TextField
                                id="password"
                                name="password"
                                type="password"
                                onChange={fieldHandleChange("password")}
                                onBlur={handleBlur}
                                value={values.password}
                                disabled={isPending}
                                variant="standard" label="Password"/>
                            <div className={classes.error}>
                                <ErrorMessage name="password"/>
                            </div>
                        </Box>

                        <Button color="primary" variant="contained" type="submit" disabled={isPending}>
                            {isPending && <CircularProgress color="inherit" size={20} style={{marginRight: 20}} />}
                            Log In
                        </Button>
                    </form>
                </FormikProvider>
            </Content>
        </>
    )
}

export default observer(Login)