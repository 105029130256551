export const FORM_REGISTRATION = 'Sign up for driving school';
export const FORM_NAME = 'First Name';
export const FORM_LASTNAME = 'Last Name';
export const FORM_EMAIL = 'Email';
export const FORM_PHONE = 'Phone number';
export const FORM_PASSWORD = 'Password';
export const FORM_CONFIRM_PASSWORD = 'Confirm password';
export const FORM_DRIVE = 'Driving License Categories';
export const FORM_SUBSCRIBE = 'Subscribe to newsletters';
export const FORM_REGISTER = 'Sign up';
export const FORM_PROFILE = 'Profile';
export const FORM_BIRTHDAY = 'Birthday';
export const FORM_HOBBY = 'Hobbies';
export const FORM_AVATAR = 'Avatar';
export const FORM_LOAD_IMG = 'Upload image';
export const FORM_REMOVE_IMG = 'Delete image';
export const FORM_BACK = 'Back';
export const FORM_FORWARD = 'Next';
export const FORM_SAVE = 'Save';
export const FORMAT = 'dd.MM.yyyy';
export const DEFAULT_AVATAR = '../../../public/avatar.png';
export const MAX_AVATAR_SIZE = 'Maximum image size - 5 МБ';
export const LICENSES = ["А1", "А", "В1", "В", "С1", "С", "D1", "D", "С1Е", "ВЕ", "СЕ", "D1E", "DE", "T1", "T2", "S", "S1", "S2"];
export const REGISTER_URL = "register";
export const PROFILE_URL = "profile";

export const FINAL_PHRASE = 'Take a screenshot and add it to the answer to the task 🤗'
