import React, {ReactNode} from "react";
import {makeStyles} from "@mui/styles";
import {Typography} from "@mui/material";

const useStyles = makeStyles({
    root: {
        backgroundColor: "#EFEFEF",
        padding: 25,
        textAlign: "center",
        borderBottom: "1px solid #DDDDDD"
    }
})

export const Header: React.FC<ReactNode> = ({children}) => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Typography variant="h5">
                {children}
            </Typography>
        </div>
    )
}