import React, {FocusEventHandler, useContext, useEffect, useState} from "react";
import {Box, Button, CircularProgress, Container, Modal, TextField, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {observer} from "mobx-react-lite";
import LoggedHeader from "../components/LoggedHeader";
import {
    FINAL_PHRASE,
    FORM_BACK,
    FORM_EMAIL,
    FORM_HOBBY,
    FORM_LASTNAME,
    FORM_NAME,
    FORM_PHONE,
    FORM_PROFILE,
    FORM_SAVE,
    FORMAT,
    REGISTER_URL
} from '../constants'
import {FormikProvider, useFormik} from "formik";
import StoreContext from "../mobx";
import {Link, useNavigate, useParams} from "react-router-dom";
import 'react-day-picker/lib/style.css'
import MyAvatar from "../components/Profile/MyAvatar";
import License from "../components/Profile/License";
import Birthday from "../components/Profile/Birthday";
import dateFnsFormat from "date-fns/format";

const useStyles = makeStyles({
    container: {
        height: "100%",
        position: "relative"
    },
    error: {
        paddingTop: 5,
        color: "red",
    },
    driveDigit: {
        marginLeft: 10,
        width: 70,
        textAlign: "right"
    },
    submitBtn: {
        position: "relative",
        left: 20,
    },
    success: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        height: 120,
        backgroundColor: "#FFFFFF",
        border: '2px solid #000',
        padding: 14,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
})

interface IValidate{
    about: string,
    avatar: string
}

interface ICard {
    name: string,
    surname: string,
    email: string,
    phone: string,
    drivingLicense: string[],
    profileImage: string,
    birthday: string,
    about: string
}

const initial = {
    name: "",
    surname: "",
    email: "",
    phone: "",
    drivingLicense: [] as string[],
    profileImage: "",
    birthday: "",
    about: ""
}

const Profile: React.FC = () => {
    const [success, setSuccess] = useState(false)
    const {id} = useParams();
    const navigate = useNavigate();
    const classes = useStyles();
    const [formErrors, setFormErrors] = useState({} as IValidate);
    const [hasEmptyValue, setHasEmptyValue] = useState(true);
    const {cardStore} = useContext(StoreContext);

    const handlerValidate = (values: ICard) => {
        const newErrors = {avatar: formErrors.avatar} as IValidate;
        if (values.about.length > 250) {
            newErrors.about = "limit 255 signs"
        }
        setFormErrors(newErrors);
    }

    const formik = useFormik({
        initialValues: initial,
        validate: handlerValidate,
        onSubmit: async (values: any) => {
            try {
                const body = {
                    birthday: values.birthday,
                    drivingLicense: values.drivingLicense,
                    about: values.about,
                    profileImage: values.profileImage,
                };

                const response = await cardStore.updateCard(cardStore.card.id, body)
                if (response?.success) {
                    setSuccess(true);
                }
            } catch (e: any) {
                console.log(e.response)
            }
        },
        validateOnChange: false,
    });
    const {handleChange, isSubmitting, handleSubmit, values, setValues, setFieldTouched} = formik;
    const formatDate = (date: Date, format: string) => {
        return dateFnsFormat(date, format);
    }

    useEffect(() => {
        setHasEmptyValue(Object.values(values).some(value => (value === "" || value.length === 0)));
    }, [values]);

    useEffect(() => {
        async function getCard() {
            if (id) {
                try {
                    await cardStore.getCard(id);
                    const {name, surname, email, phone, about, birthday, drivingLicense} = cardStore.card;
                    await setValues({
                        ...initial,
                        name,
                        surname,
                        email,
                        phone,
                        about: about || "",
                        birthday: birthday ? formatDate(new Date(birthday), FORMAT) : "",
                        drivingLicense: JSON.parse(drivingLicense || '[]')
                    });
                } catch (e) {
                    navigate(`/${REGISTER_URL}`);
                }
            } else {
                navigate(`/${REGISTER_URL}`);
            }
        }

        getCard()
    }, [])

    const myHandleBlur: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> = ((e) => {
        const name = e.currentTarget.name;
        setFieldTouched(name);
    })


    return (
        <Container maxWidth="xs" className={classes.container}>
            <Box mb={3} display="flex" justifyContent="right">
                <LoggedHeader />
            </Box>
            <Box sx={{marginLeft: {xs: 0, md: -25}, marginRight: {xs: 0, md: -25}}}>
                <Typography variant="h4" align="center">{FORM_PROFILE}</Typography>
            </Box>
            <Box>
                <FormikProvider value={formik}>
                    <form onSubmit={handleSubmit}>
                        <Box display="flex" flexDirection="column" position="relative">
                            <Box pt={2} pb={1} display="block">
                                <TextField
                                    id="name"
                                    name="name"
                                    value={values.name}
                                    variant="outlined"
                                    label={FORM_NAME}
                                    fullWidth
                                    inputProps={{
                                        pattern: "[a-zA-Z]+"
                                    }}
                                    disabled
                                />
                            </Box>
                            <Box pt={2} pb={1} display="block">
                                <TextField
                                    id="surname"
                                    name="surname"
                                    value={values.surname}
                                    variant="outlined"
                                    label={FORM_LASTNAME}
                                    fullWidth
                                    disabled
                                />
                            </Box>
                            <Box pt={2} pb={1} display="block">
                                <TextField
                                    id="email"
                                    name="email"
                                    value={values.email}
                                    variant="outlined"
                                    label={FORM_EMAIL}
                                    fullWidth
                                    disabled
                                />
                            </Box>
                            <Box pt={2} pb={1} display="block" position="relative">
                                <TextField
                                    id="phone"
                                    name="phone"
                                    value={`+38${values.phone}`}
                                    variant="outlined"
                                    label={FORM_PHONE}
                                    fullWidth
                                    disabled
                                />
                            </Box>
                            <Box pt={2} pb={1}>
                                <Birthday value={values.birthday} setValue={(birthday) => {
                                    const newValues = {...values, birthday}
                                    setValues(newValues);
                                }} />
                            </Box>
                            <Box pt={2} pb={1}>
                                <TextField
                                    id="about"
                                    name="about"
                                    label={FORM_HOBBY}
                                    multiline
                                    rows={4}
                                    fullWidth
                                    value={values.about}
                                    placeholder="my hobby is ..."
                                    onChange={handleChange}
                                    onBlur={myHandleBlur}
                                />
                                { formErrors.about && <div className={classes.error}>{formErrors.about}</div>}
                            </Box>
                            <Box pt={2} pb={1} display="block" position="relative">
                                <MyAvatar
                                    setError={(val: string) => setFormErrors({...formErrors, avatar: val})}
                                    setValues={(profileImage: string) => setValues({...values, profileImage})}
                                />
                                { formErrors.avatar && <div className={classes.error}>{formErrors.avatar}</div>}
                            </Box>
                            <Box pt={2} pb={1} display="flex" justifyContent="space-between">
                                <License
                                    setValues={(drivingLicense: string[]) => setValues({...values, drivingLicense})}
                                    licenses={values.drivingLicense}
                                />
                            </Box>
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={hasEmptyValue}
                                className={classes.submitBtn}
                            >
                                {isSubmitting && <CircularProgress />} {FORM_SAVE}
                            </Button>
                            <Box mt={2} mb={4}>
                                {id && (<Link to={`/${REGISTER_URL}/${id}`} style={{textDecoration: "none"}}><Button variant="contained" fullWidth>{FORM_BACK}</Button></Link>)}
                            </Box>
                        </Box>
                    </form>
                </FormikProvider>
            </Box>
            <Modal
                open={success}
                onClose={() => setSuccess(false)}
            >
                <Box className={classes.success}>
                    {FINAL_PHRASE}
                </Box>
            </Modal>
        </Container>
    )
}

export default observer(Profile);
