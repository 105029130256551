import React from "react";
import {Container, Grid, Paper} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Login from "../components/Login";

const useStyles = makeStyles({
    container: {
        height: "100%",
    },
    wrapper: {
        position: "relative"
    },
})

const LoginPage: React.FC = () => {
    const classes = useStyles();

    return (
        <Container maxWidth="xs" className={classes.container}>
            <Grid display="flex" flexDirection="column" justifyContent="center" height={1}>
                <Paper elevation={1} className={classes.wrapper}>
                    <Login />
                </Paper>
            </Grid>
        </Container>
    )
}

export default LoginPage;