import axios from 'axios';
import {IUser} from "../model";

const API_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true'
    },
})

api.interceptors.request.use((config) => {
    if (config?.headers && config.url !== "/auth/signin") {
        config.headers.Authorization = "Bearer " + localStorage.accessToken;
    }
    return config;
})

api.interceptors.response.use((config) => {
    return config;
}, async (error) => {
    const originalRequest = error.config;
    try {
        if (error.response.status === 401 && originalRequest.url !== "/auth/signin") {
            const response = await axios.post<IUser>(`${API_URL}/auth/refreshtoken`, {
                "accessToken": localStorage.accessToken,
                "refreshToken": localStorage.refreshToken
            })
            localStorage.accessToken = response.data.accessToken;
            localStorage.refreshToken = response.data.refreshToken;
            return api.request(originalRequest);
        }
        return Promise.reject(error);
    } catch (e) {
        console.log("Wrong Authorization")
        return Promise.reject(error);
    }
})
export default api;
