import {
    Box,
    BoxProps,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import Loading from "./Loading";
import React from "react";
import {IUpdateUser, IUserInfo} from "../model";
import {makeStyles} from "@mui/styles";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import EditIcon from '@mui/icons-material/Edit';

interface IUserList extends BoxProps {
    list: IUserInfo[],
    isPending: boolean,
    title: string,
    removeHandler: (id: number) => void
    updateHandler: (id: number, data: IUpdateUser) => void
    editHandler: (id: number) => void
}

const useStyles = makeStyles({
    row: {
        "&:hover": {
            backgroundColor: "#EFEFEF"
        }
    },
})

const UsersList: React.FC<IUserList> = ({
                                            list,
                                            isPending,
                                            title,
                                            removeHandler,
                                            updateHandler,
                                            editHandler,
                                            ...rest
                                        }) => {
    const classes = useStyles();

    if (!isPending && list.length === 0) {
        return null;
    }

    return (
        <Box {...rest}>
            <Typography variant="h3">{title}</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Name</TableCell>
                            <TableCell align="center">Email</TableCell>
                            <TableCell align="center">isAdmin</TableCell>
                            <TableCell align="center">isActive</TableCell>
                            <TableCell align="center"> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isPending ? (
                                <TableRow>
                                    <TableCell>
                                        <Loading/>
                                    </TableCell>
                                </TableRow>
                            ) :
                            list.map(userItem => (
                                <TableRow key={userItem.id} className={classes.row}>
                                    <TableCell align="center">{userItem.name}</TableCell>
                                    <TableCell align="center">{userItem.email}</TableCell>
                                    <TableCell align="center">
                                        <div onClick={() => updateHandler(userItem.id, {isAdmin: !userItem.isAdmin})}>
                                            {userItem.isAdmin ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>}
                                        </div>
                                    </TableCell>
                                    <TableCell align="center">
                                        <div onClick={() => updateHandler(userItem.id, {isActive: !userItem.isActive})}>
                                            {userItem.isActive ? <CheckBoxIcon cursor="pointer"/> :
                                                <CheckBoxOutlineBlankIcon cursor="pointer"/>}
                                        </div>
                                    </TableCell>
                                    <TableCell align="center">
                                        <EditIcon color="primary" onClick={() => editHandler(userItem.id)}/>
                                        <HighlightOffIcon onClick={() => removeHandler(userItem.id)} color="error"
                                                          height={30} width={30} cursor="pointer"/>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
};
export default UsersList;