import {INewCard, IUpdateCard} from "../model";
import CardService from "./CardServices";

class CardController {
    static async getCard(id: string) {
        try {
            const response = await CardService.getCard(id);
            if (response.status === 200 || response.status === 201) {
                return {
                    success: true,
                    data: response.data,
                };
            }
            throw new Error('Something went wrong');
        } catch (e: any) {
            return Promise.reject(e.response.data);
        }
    }

    static async createCard(body: INewCard) {
        try {
            const response = await CardService.createCard(body);
            if (response.status === 200 || response.status === 201) {
                return {
                    success: true,
                    data: response.data,
                };
            }
            throw new Error('Something went wrong');
        } catch (e: any) {
            return Promise.reject(e.response.data);
        }
    }

    static async updateCard(id: number, data: IUpdateCard) {
        try {
            const response = await CardService.updateCard(id, data);
            if (response.status === 200) {
                return {
                    success: true,
                    data: response.data,
                };
            }
            throw new Error('Something went wrong');
        } catch (e: any) {
            return Promise.reject(e.response.data);
        }
    }
}

export default CardController;
