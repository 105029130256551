import UserService from "./UserServices";
import {INewUser, ISignInUser, IUpdateUser} from "../model";

class UsersController {
    static async getAllUsers() {
        try {
            const response = await UserService.getAllUsers();
            if (response.status === 200 || response.status === 201) {
                return {
                    success: true,
                    data: response.data,
                };
            }
            throw new Error('Something went wrong');
        } catch (e: any) {
            return Promise.reject(e.response.data);
        }
    }

    static async signIn(body: ISignInUser) {
        try {
            const response = await UserService.signIn(body);
            if (response.status === 200 || response.status === 201) {
                return {
                    success: true,
                    data: response.data,
                };
            }
            throw new Error('Something went wrong');
        } catch (e: any) {
            return Promise.reject(e.response);
        }
    }

    static async signUp(body: INewUser) {
        try {
            const response = await UserService.createUser(body);
            if (response.status === 200 || response.status === 201) {
                return {
                    success: true,
                    data: response.data,
                };
            }
            throw new Error('Something went wrong');
        } catch (e: any) {
            return Promise.reject(e.response.data);
        }
    }

    static async me() {
        try {
            const response = await UserService.me();
            if (response.status === 200 || response.status === 201) {
                return {
                    success: true,
                    data: response.data,
                };
            }
            throw new Error('Something went wrong');
        } catch (e: any) {
            return Promise.reject(e.response.data);
        }
    }

    static async deleteUser(id: number) {
        try {
            const response = await UserService.hardDeleteUser(id);
            if (response.status === 204) {
                return {
                    success: true,
                };
            }
            throw new Error('Something went wrong');
        } catch (e: any) {
            return Promise.reject(e.response.data);
        }
    }

    static async updateUser(id: number, data: IUpdateUser) {
        try {
            const response = await UserService.updateUser(id, data);
            if (response.status === 200) {
                return {
                    success: true,
                };
            }
            throw new Error('Something went wrong');
        } catch (e: any) {
            return Promise.reject(e.response.data);
        }
    }

    static async logout() {
        try {
            const response = await UserService.logout();
            if (response.status === 204) {
                return {
                    success: true,
                };
            }
            throw new Error('Something went wrong');
        } catch (e: any) {
            return Promise.reject(e.response.data);
        }
    }

}

export default UsersController;
