import api from "./api";
import {ICardResponse, INewCard, IUpdateCard} from "../model";
import {AxiosResponse} from "axios";

export default class CardService {
    static async createCard(body: INewCard): Promise<AxiosResponse<ICardResponse>> {
        return api.post(`/user-cards`, body);
    }

    static async getCard(id: string): Promise<AxiosResponse<ICardResponse>> {
        return api.get(`/user-cards/${id}`);
    }

    static async updateCard(id: number, body: IUpdateCard): Promise<AxiosResponse<ICardResponse>> {
        return api.put(`/user-cards/${id}`, body);
    }
}
