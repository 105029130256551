import {Avatar, Box, Button, CircularProgress} from "@mui/material";
import React, {useRef, useState} from "react";
import {makeStyles} from "@mui/styles";
import {DEFAULT_AVATAR, FORM_AVATAR, FORM_LOAD_IMG, FORM_REMOVE_IMG, MAX_AVATAR_SIZE} from "../../constants";

const useStyles = makeStyles({
    header: {
        fontSize: 16,
        marginBottom: 10
    },
    button: {
        marginBottom: 10
    }
})

interface IAvatar {
    setError: (value: string) => void
    setValues: (profileImage: string) => void
}

const MyAvatar: React.FC<IAvatar> = ({setError, setValues}) => {
    const [src, setSrc] = useState(DEFAULT_AVATAR);
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    const avatarRef = useRef<HTMLInputElement>(null);
    const avatarHandleChange = () => {
        setError("");
        if (avatarRef?.current?.files) {
            const file = avatarRef.current.files[0];
            if (file.size > 20971520) {
                return;
            } else if (file.size > 5242880) {
                setError(MAX_AVATAR_SIZE);
            }
            setIsLoading(true);
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setIsLoading(false)
                if (typeof reader.result === "string") {
                    setSrc(reader.result);
                }
            };
            setValues(file.name);
        }
    };
    const handleRemove = () => {
        setError("");
        setSrc(DEFAULT_AVATAR);
        setValues("");
    }

    return (
            <Box display="flex">
                <Box>
                    <div className={classes.header}>{FORM_AVATAR}</div>
                    <Avatar sx={{
                        height: 80,
                        width: 80
                    }} src={src} />
                </Box>
                <Box display="flex" flexDirection="column" ml={2} pt={2}>
                    <Box mb={2} width={1}>
                        <Button variant="contained" fullWidth>
                            {isLoading && <CircularProgress size={20} sx={{color: "white", marginRight: 1}} />}
                            <label>
                                {FORM_LOAD_IMG}
                                <input
                                    ref={avatarRef}
                                    type="file"
                                    multiple
                                    accept=".png, .jpg, .jpeg"
                                    hidden
                                    onChange={avatarHandleChange}
                                />
                            </label>
                        </Button>
                    </Box>
                    <Button variant="contained" disabled={src === DEFAULT_AVATAR} onClick={handleRemove}>{FORM_REMOVE_IMG}</Button>
                </Box>
            </Box>
    )
}

export default MyAvatar