import {Box, Button} from "@mui/material";
import React, {useContext} from "react";
import StoreContext from "../mobx";
import {observer} from "mobx-react-lite";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    btn: {
        whiteSpace: "nowrap"
    },
    name: {
        fontWeight: 600
    },
    email: {
        color: "#777777"
    },
    icon: {
        borderRadius: "50%",
        width: 40,
        height: 40,
        marginRight: 10,
        overflow: "hidden",
        "& img": {
            width: "100%",
        }
    }
})

const LoggedHeader: React.FC = () => {
    const {authStore} = useContext(StoreContext);
    const classes = useStyles();
    return (
        <Box display="flex" mt={2}>
            <Box display="flex">
                <div className={classes.icon}>
                    <img src="/image.png" alt="logo" />
                </div>
                <div>
                    <div className={classes.name}>
                        {authStore.user.name}
                    </div>
                    <div className={classes.email}>
                        {authStore.user.email}
                    </div>
                </div>
            </Box>
            <Box ml={2}>
                <Button
                    onClick={() => authStore.logout()}
                    variant="contained"
                    className={classes.btn}
                >Log out</Button>
            </Box>
        </Box>
    )
};
export default observer(LoggedHeader);