import {Header} from "../wrappers/Header";
import Alert from "@mui/material/Alert";
import {Content} from "../wrappers/Content";
import {ErrorMessage, FormikProvider, useFormik} from "formik";
import {Box, Button, CircularProgress, TextField} from "@mui/material";
import React, {useContext, useState} from "react";
import {makeStyles} from "@mui/styles";
import * as Yup from "yup";
import {INewUser, ISignUp} from "../model";
import StoreContext from "../mobx";

const useStyles = makeStyles({
    error: {
        position: "absolute",
        bottom: -30,
        zIndex: 10,
        paddingTop: 5,
        paddingBottom: 10,
        color: "red",
    }
})

const initial: INewUser = {
    name: "",
    email: "",
    password: "",
}

const SignUp: React.FC<ISignUp> = ({cancel}) => {
    const [error, setError] = useState("");
    const [sending, setSending] = useState(false);
    const classes = useStyles();
    const {authStore, usersStore} = useContext(StoreContext);

    const validation = Yup.object({
        name: Yup.string()
            .required('Required'),
        email: Yup.string()
            .email('Should be email')
            .required('Required'),
        password: Yup
            .string()
            .min(6, 'the min length is 6')
            .max(30, 'the max length is 30')
            .required('required'),
    })

    const formik = useFormik({
        initialValues: initial,
        validationSchema: validation,
        onSubmit: async (values) => {
            setSending(true);
            setError("");
            try {
                const usersData = await authStore.signUp(values);
                if (usersData?.success) {
                    usersStore.addUser(usersData.data);
                    cancel();
                }
            } catch (e: any) {
                setError(e.message)
            } finally {
                setSending(false);
            }
        },
        validateOnBlur: true,
        validateOnChange: false
    });
    const {handleChange, handleBlur, handleSubmit, values, setErrors, errors} = formik;
    const fieldHandleChange: Function = (fieldName: string) => (e: React.FormEvent<HTMLFormElement>) => {
        setErrors({...errors, [fieldName]: ""});
        handleChange(e);
    }

    return (
        <>
            <Header>Sign Up</Header>
            {error && <Alert severity="error">{error}</Alert>}
            <Content>
                <FormikProvider value={formik}>
                    <form onSubmit={handleSubmit}>
                        <Box display="flex" flexDirection="column" position="relative">
                            <TextField
                                id="name"
                                name="name"
                                onChange={fieldHandleChange("name")}
                                onBlur={handleBlur}
                                value={values.name}
                                disabled={sending}
                                variant="standard" label="Name"/>
                            <div className={classes.error}>
                                <ErrorMessage name="name"/>
                            </div>
                        </Box>

                        <Box display="flex" flexDirection="column" position="relative" mb={4} mt={4}>
                            <TextField
                                id="email"
                                name="email"
                                onChange={fieldHandleChange("email")}
                                onBlur={handleBlur}
                                value={values.email}
                                disabled={sending}
                                variant="standard" label="Email"/>
                            <div className={classes.error}>
                                <ErrorMessage name="email"/>
                            </div>
                        </Box>
                        <Box display="flex" flexDirection="column" position="relative" mb={4} mt={4}>
                            <TextField
                                id="password"
                                name="password"
                                type="password"
                                onChange={fieldHandleChange("password")}
                                onBlur={handleBlur}
                                value={values.password}
                                disabled={sending}
                                variant="standard" label="Password"/>
                            <div className={classes.error}>
                                <ErrorMessage name="password"/>
                            </div>
                        </Box>

                        <Box display="flex" justifyContent="space-between">
                            <Button color="primary" variant="contained" type="submit" disabled={sending}>
                                {sending && <CircularProgress color="inherit" size={20} style={{marginRight: 20}} />}
                                Sign Up
                            </Button>
                            <Button color="secondary" variant="outlined" onClick={cancel}>
                                Cancel
                            </Button>
                        </Box>
                    </form>
                </FormikProvider>
            </Content>
        </>
    )
}

export default SignUp