import React, {useContext, useEffect} from 'react';
import './App.css';
import {Navigate, Route, Routes} from 'react-router-dom';
import LoginPage from "./Pages/LoginPage";
import StoreContext from "./mobx";
import {observer} from "mobx-react-lite";
import UsersPage from "./Pages/UsersPage";
import Loading from "./components/Loading";
import Register from "./Pages/Register";
import Profile from "./Pages/Profile";
import {PROFILE_URL, REGISTER_URL} from "./constants";

const App: React.FC = () => {
    const {authStore} = useContext(StoreContext);
    useEffect(() => {
        if (localStorage.accessToken) {
            authStore.me();
        } else {
            authStore.setPending(false);
        }
    }, []);

    const getRoutes = () => {
        if (!authStore.isAuthorized) {
            return (<>
                <Route path="/login" element={<LoginPage />}/>
                <Route path="*" element={<Navigate to="/login" />}/>
            </>)
        } else if (authStore.isSuperAdmin) {
            return (<>
                <Route path="/users" element={<UsersPage />}/>
                <Route path="*" element={<Navigate to="/users" />}/>
            </>)
        } else {
            return (<>
                <Route path={`/${REGISTER_URL}`} element={<Register />}>
                    <Route path=":id" element={<Register />} />
                </Route>
                <Route path={`/${PROFILE_URL}/:id`} element={<Profile />}/>
                <Route path="*" element={<Navigate to={`/${REGISTER_URL}`} />}/>
            </>)
        }
    }

    const routes = getRoutes();
    return (
    <>
        {authStore.isPending ?
            <Loading />
        : (
            <Routes>
                {routes}
            </Routes>
            )}
    </>
  );
}

export default observer(App);
