import Auth from "./Auth";
import React from "react";
import Users from "./Users";
import Cards from "./Cards";

interface IStoreContext {
    authStore: Auth,
    usersStore: Users,
    cardStore: Cards
}
const authStore = new Auth();
const usersStore = new Users();
const cardStore = new Cards();
const StoreContext = React.createContext<IStoreContext>({
    authStore,
    usersStore,
    cardStore,
});

export default StoreContext;
