import {
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent
} from "@mui/material";
import React from "react";
import {FORM_DRIVE, LICENSES} from "../../constants";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        sx: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: "300px",
            '& .MuiList-root': {
                display: "flex",
                flexWrap: "wrap"
            },
        },
    }
};

interface ILicense {
    licenses: string[],
    setValues: (drivingLicense: string[]) => void
}

const License: React.FC<ILicense> = ({setValues, licenses = []}) => {
    const names = LICENSES;
    const selectorHandleChange = (event: SelectChangeEvent<typeof licenses>) => {
        const {
            target: { value },
        } = event;
        const license = typeof value === 'string' ? value.split(',') : value;
        setValues(license);
    };

    return (
        <>
            <FormControl sx={{ m: 1, width: "100%" }}>
                <InputLabel>{FORM_DRIVE}</InputLabel>
                <Select
                    id="driveLicense"
                    name="driveLicense"
                    multiple
                    value={licenses}
                    onChange={selectorHandleChange}
                    input={<OutlinedInput label={FORM_DRIVE} />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                >
                    {names.map((name) => (
                        <MenuItem key={name} value={name}>
                            <Checkbox checked={licenses.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    )
}

export default License