import React, {ChangeEventHandler, FocusEventHandler, useContext, useEffect, useState} from "react";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Container,
    FormControlLabel,
    TextField,
    Typography
} from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import {makeStyles} from "@mui/styles";
import {observer} from "mobx-react-lite";
import LoggedHeader from "../components/LoggedHeader";
import {INewCard} from "../model";
import {
    FORM_CONFIRM_PASSWORD,
    FORM_DRIVE,
    FORM_EMAIL, FORM_FORWARD,
    FORM_LASTNAME,
    FORM_NAME,
    FORM_PASSWORD,
    FORM_PHONE, FORM_REGISTER,
    FORM_REGISTRATION,
    FORM_SUBSCRIBE, PROFILE_URL, REGISTER_URL
} from "../constants";
import {FormikProvider, useFormik} from "formik";
import StoreContext from "../mobx";
import {Link, useNavigate, useParams} from "react-router-dom";

const useStyles = makeStyles({
    container: {
        height: "100%",
        position: "relative"
    },
    error: {
        paddingTop: 5,
        color: "red",
    },
    driveDigit: {
        marginLeft: 10,
        width: 70,
        textAlign: "right"
    }
})

interface IValidate{
    name: string,
    surname: string,
    email: string,
    password: string,
    passwordConfirmation: string,

}

const initial = {
    name: "",
    surname: "",
    email: "",
    password: "",
    passwordConfirmation: "",
    phone: "",
    drivingLicense: "",
    isSubscribed: false,
}

const Register: React.FC = () => {
    const {id} = useParams();
    const classes = useStyles();
    const [formErrors, setFormErrors] = useState({} as IValidate);
    const [hasEmptyValue, setHasEmptyValue] = useState(true);
    const navigate = useNavigate();
    const {cardStore} = useContext(StoreContext);

    const handlerValidate = (values: INewCard, fieldName: string) => {
        const {name, surname, email, password, passwordConfirmation} = values;
        const newErrors = {} as IValidate;
        if ((touched.name || fieldName === "name") && (name.length < 2 || name.length > 255)){
            newErrors.name = "2 - 255 letters";
        }

        if ((touched.surname || fieldName === "surname") && (surname.length < 2 || surname.length > 255)){
            newErrors.surname = "2 - 255 letters";
        }

        if ((touched.email || fieldName === "email")  && !/@/.test(email)) {
            newErrors.email = "Invalid email";
        }
        if ((touched.passwordConfirmation || fieldName === "passwordConfirmation")  && password !== passwordConfirmation) {
            newErrors.passwordConfirmation = "Passwords do not match";
        }
        setFormErrors(newErrors);
    }

    const formik = useFormik({
        initialValues: initial,
        onSubmit: async (values: INewCard) => {
            try {
                const body = {
                    name: values.name,
                    surname: values.surname,
                    email: values.email,
                    password: values.password,
                    passwordConfirmation: values.passwordConfirmation,
                    phone: values.phone,
                    isSubscribed: values.isSubscribed,
                };

                const response = await cardStore.createCard(body);
                const id = response?.data.id;
                if (id) {
                    window.open(`/${PROFILE_URL}/${id}`, "_blank");
                    document.cookie = "subscription=undefined";
                    navigate(`/${REGISTER_URL}/${id}`);
                }
            } catch (e: any) {
                console.log(e.response)
            }
        },
        validateOnChange: false,
    });
    const {handleChange, isSubmitting, handleSubmit, values, touched, setValues, setFieldTouched} = formik;

    const nameHandleChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
        const val = e.currentTarget.value.replace(/[^a-zA-Z]/g, "");
        setValues({...values, name: val});
    }
    const phoneHandleChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
        const phone = e.currentTarget.value.replace(/\D/g, "");
        setValues({...values, phone});
    }

    useEffect(() => {
        setHasEmptyValue(Object.values(values).some(value => (value === "" || value === false)));
    }, [values]);

    useEffect(() => {
        async function getCard() {
            if (id) {
                await cardStore.getCard(id);
                const {name, surname, email, phone} = cardStore.card;
                await setValues({
                    ...values,
                    name,
                    surname,
                    email,
                    phone,
                    isSubscribed: true
                });
            }
        }
        getCard()
    }, [])

    const myHandleBlur: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> = ((e) => {
        const name = e.currentTarget.name;
        handlerValidate(values, name);
        setFieldTouched(name);
    })

    return (
        <Container maxWidth="xs" className={classes.container}>
            <Box mb={3} display="flex" justifyContent="right">
                <LoggedHeader />
            </Box>
            <Box>
                <Typography variant="h4">{FORM_REGISTRATION}</Typography>
            </Box>
            <Box>
                <FormikProvider value={formik}>
                    <form onSubmit={handleSubmit}>
                        <Box display="flex" flexDirection="column" position="relative">
                            <Box pt={2} pb={1} display="block">
                                <TextField
                                    id="name"
                                    name="name"
                                    onChange={nameHandleChange}
                                    onBlur={myHandleBlur}
                                    value={values.name}
                                    variant="outlined"
                                    label={FORM_NAME}
                                    fullWidth
                                    inputProps={{
                                        pattern: "[a-zA-Z]+"
                                    }}
                                />
                                { formErrors.name && <div className={classes.error}>{formErrors.name}</div>}
                            </Box>
                            <Box pt={2} pb={1} display="block">
                                <TextField
                                    id="surname"
                                    name="surname"
                                    onChange={handleChange}
                                    onBlur={myHandleBlur}
                                    value={values.surname}
                                    variant="outlined"
                                    label={FORM_LASTNAME}
                                    fullWidth
                                />
                                { formErrors.surname && <div className={classes.error}>{formErrors.surname}</div>}
                            </Box>
                            <Box pt={2} pb={1} display="block">
                                <TextField
                                    id="email"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={myHandleBlur}
                                    value={values.email}
                                    variant="outlined"
                                    label={FORM_EMAIL}
                                    fullWidth
                                />
                                { formErrors.email && <div className={classes.error}>{formErrors.email}</div>}
                            </Box>
                            <Box pt={2} pb={1} display="block">
                                <TextField
                                    id="phone"
                                    name="phone"
                                    onBlur={myHandleBlur}
                                    value={values.phone}
                                    variant="outlined"
                                    label={FORM_PHONE}
                                    onChange={phoneHandleChange}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">+380</InputAdornment>,
                                    }}
                                    inputProps={{
                                        maxLength: 10
                                    }}
                                />
                            </Box>
                            <Box pt={2} pb={1} display="block">
                                <TextField
                                    id="password"
                                    name="password"
                                    onChange={handleChange}
                                    onBlur={myHandleBlur}
                                    value={values.password}
                                    variant="outlined"
                                    inputProps={{
                                        type: "password",
                                    }}
                                    label={FORM_PASSWORD}
                                    fullWidth
                                />
                            </Box>
                            <Box pt={2} pb={1} display="block">
                                <TextField
                                    id="passwordConfirmation"
                                    name="passwordConfirmation"
                                    onChange={handleChange}
                                    onBlur={myHandleBlur}
                                    inputProps={{
                                        type: "password",
                                    }}
                                    value={values.passwordConfirmation}
                                    variant="outlined"
                                    label={FORM_CONFIRM_PASSWORD}
                                    fullWidth
                                />
                                { formErrors.passwordConfirmation && <div className={classes.error}>{formErrors.passwordConfirmation}</div>}
                            </Box>
                            <Box pt={2} pb={1} display="flex" justifyContent="space-between">
                                <TextField
                                    style={{width: "calc(100% - 90px)"}}
                                    id="drivingLicense"
                                    name="drivingLicense"
                                    onChange={handleChange}
                                    onBlur={myHandleBlur}
                                    value={values.drivingLicense}
                                    variant="outlined"
                                    label={FORM_DRIVE}
                                    inputProps={{
                                        maxLength: 255
                                    }}
                                />
                                <TextField
                                    id="drivingLicenseNumber"
                                    name="drivingLicenseNumber"
                                    variant="outlined"
                                    inputProps={{
                                        style: { textAlign: "right" },
                                        type: "number",
                                        maxLength: 255
                                    }}
                                    className={classes.driveDigit}
                                />
                            </Box>
                            <FormControlLabel
                                control={<Checkbox
                                    id="isSubscribed"
                                    name="isSubscribed"
                                    onChange={handleChange}
                                    checked={values.isSubscribed}
                                />}
                                label={FORM_SUBSCRIBE} />
                            {!hasEmptyValue && (
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={isSubmitting || !!id}
                                >
                                    {isSubmitting && <CircularProgress />} {FORM_REGISTER}
                                </Button>)}
                            {id && (<Link
                                to={`/${PROFILE_URL}/${id}`}
                                style={{textDecoration: "none", marginTop: 20}}
                            >
                                <Button variant="contained">{FORM_FORWARD}</Button>
                            </Link>)}
                        </Box>
                    </form>
                </FormikProvider>
            </Box>
        </Container>
    )
}

export default observer(Register);