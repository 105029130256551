import {CircularProgress} from "@mui/material";
import {makeStyles} from "@mui/styles";
import React from "react";

const useStyles = makeStyles({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1111,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.1)"
    }
})

const Loading: React.FC = () => {
    const classes = useStyles();
    return (
        <div className={classes.root} data-testid="loading">
            <CircularProgress size={100} />
        </div>
    )
}

export default Loading;