import React, {useContext, useEffect, useState} from "react";
import {Box, Container, Dialog, Fab} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {observer} from "mobx-react-lite";
import StoreContext from "../mobx";
import UsersList from "../components/UsersList";
import AddIcon from "@mui/icons-material/Add";
import SignUp from "../components/SignUp";
import LoggedHeader from "../components/LoggedHeader";
import {IUpdateUser} from "../model";
import EditUser from "../components/EditUser";

const useStyles = makeStyles({
    container: {
        height: "100%",
        position: "relative"
    },
    signupModal: {
        textAlign: "right",
        marginTop: 10
    },
})

const UsersPage: React.FC = () => {
    const classes = useStyles();
    const {usersStore} = useContext(StoreContext);
    const [signUp, setSignUp] = useState(false)
    const [edit, setEdit] = useState(-1);

    useEffect(() => {
        usersStore.getAllUsers();
    }, []);

    const openSignUp = () => setSignUp(true);
    const closeSignUp = () => setSignUp(false);

    const removeHandler = (id: number) => usersStore.deleteUser(id);
    const updateHandler = (id: number, data: IUpdateUser) => usersStore.updateUser(id, data);
    const closeEdit = () => setEdit(-1);
    return (
        <Container maxWidth="sm" className={classes.container}>
            <Box mb={3} display="flex" justifyContent="right">
                <LoggedHeader />
            </Box>
            <UsersList
                list={usersStore.usersList.filter(user => user.isAdmin)}
                isPending={usersStore.isPending}
                title="Admins"
                removeHandler={removeHandler}
                updateHandler={updateHandler}
                editHandler={setEdit}
            />
            <UsersList
                list={usersStore.usersList.filter(user => !user.isAdmin)}
                isPending={usersStore.isPending}
                title="Users"
                removeHandler={removeHandler}
                updateHandler={updateHandler}
                editHandler={setEdit}
                mt={4}
            />
            <div className={classes.signupModal}>
                <Fab color="primary" aria-label="add" onClick={() => openSignUp()}>
                    <AddIcon />
                </Fab>
            </div>
            <Dialog
                open={signUp}
                onClose={closeSignUp}
                maxWidth="xs"
                fullWidth
            >
                <SignUp
                    cancel={closeSignUp}
                />
            </Dialog>
            <Dialog
                open={edit !== -1}
                onClose={closeEdit}
                maxWidth="xs"
                fullWidth
            >
                <EditUser
                    userData={usersStore.getUser(edit)}
                    onClose={closeEdit}
                />
            </Dialog>
        </Container>
    )
}

export default observer(UsersPage);