import {makeAutoObservable} from "mobx";
import {ICardResponse, INewCard, IUpdateCard} from "../model";
import CardController from "../controller/CardController";

class Cards {
    card = {} as ICardResponse;
    isPending = false;

    constructor() {
        makeAutoObservable(this);
    }

    setPending(value: boolean) {
        this.isPending = value;
    }

    setCard (card: ICardResponse) {
        this.card = card
    }

    async getCard (id: string) {
        this.setPending(true);
        try {
            const response = await CardController.getCard(id);
            this.setCard(response.data);
        } catch (e: any) {
            console.error(e.message);
        } finally {
            this.setPending(false);
        }
    }

    async updateCard (id: number, data: IUpdateCard) {
        try {
            const response = await CardController.updateCard(id, data);
            if (response.success) {
                this.setCard(response.data);
            }
            return response;
        } catch (e: any) {
            console.error(e.message);
        } finally {
            this.setPending(false);
        }
    }

    async createCard (data: INewCard) {
        try {
            const response = await CardController.createCard(data);
            if (response.success) {
                this.setCard(response.data);
            }
            return response;
        } catch (e: any) {
            console.error(e.message);
        } finally {
            this.setPending(false);
        }
    }
}
export default Cards;
