import {makeAutoObservable} from "mobx";
import {INewUser, ISignInUser, IUser} from "../model";
import UsersController from "../controller";

class Auth {
    user = {} as IUser;
    isAuthorized = false;
    isSuperAdmin = false;
    loginError = '';
    isPending = true;

    constructor() {
        makeAutoObservable(this);
    }

    clearData() {
        this.user = {} as IUser;
        this.isAuthorized = false;
        this.isSuperAdmin = false;
    }

    setAuthorized(isAuth: boolean) {
        this.isAuthorized = isAuth;
    }

    setSuperAdmin(isSuperAdmin: boolean) {
        this.isSuperAdmin = isSuperAdmin;
    }

    setLoginError(value: string){
        this.loginError = value;
    }

    setPending(value: boolean) {
        this.isPending = value;
    }

    setUser(user: IUser){
        this.user = user;
        this.setAuthorized(true);
    }

    async signIn ({email, password}: ISignInUser) {
        this.setLoginError("");
        try {
            const response = await UsersController.signIn({email, password});
            localStorage.accessToken = response.data.accessToken;
            localStorage.refreshToken = response.data.refreshToken;
            this.setUser(response.data);
            this.setAuthorized(true);
            this.setSuperAdmin(response.data.isAdmin);
        } catch (e: any) {
            console.error(e.data.message);
            this.setLoginError(e.data.message)
        }
    }

    async me () {
            this.setPending(true);
            try {
                const response = await UsersController.me();
                this.setUser(response.data);
                this.setAuthorized(true);
                this.setSuperAdmin(response.data.isAdmin);
            } catch (e: any) {
                console.log(e.message);
            } finally {
                this.setPending(false);
            }
    }

    async signUp ({name, email, password}: INewUser) {
        try {
            return await UsersController.signUp({name, email, password});
        } catch (e: any) {
            console.error(e.message);
        }
    }

    async logout() {
        this.setPending(true);
        try {
            await UsersController.logout();
            this.clearData();
            delete localStorage.refreshToken;
            delete localStorage.accessToken;
        } catch (e: any) {
            console.error(e.message);
        } finally {
            this.setPending(false);
        }
    }
}
export default Auth;
